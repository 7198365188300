import { Container } from "@material-ui/core"
import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { Layout } from "../components/layout"
import queryString from "query-string"
import Breadcrumb from "../components/Breadcrumb"

const NotFoundPage = ({ data, location }) => {
  const breadcrumbData = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Email has been sent",
      url: "/forgotten-password",
    },
  ]

  const { email } = queryString.parse(location.search)
  return (
    <Layout title={"Graduate Fashion Week - Password reset email sent"}>
      <div style={{ backgroundColor: "#FAFAFA", width: "100%" }}>
        <Container maxWidth={"lg"}>
          <Breadcrumb data={breadcrumbData} />
        </Container>
      </div>

      <StyledConatiner maxWidth={"sm"}>
        <h2>Check your inbox</h2>

        <h4>We’ve just emailed you at {email}</h4>
        <p>
          You should get your email within a few minutes with instructions for resetting your password. Please check
          your inbox. If you can’t find the email please check your junk folder.{" "}
        </p>
        <Divider />
        <Link to={"/forgotten-password"}>I've still not received the email?</Link>
      </StyledConatiner>
    </Layout>
  )
}

const Divider = styled.div`
  content: " ";
  border-top: 2px solid black;
  width: 100px;
  max-height: 2px;
  height: 2px;
  margin: auto;
  padding-top: 35px;
  /* padding-bottom: 35px; */
`

const StyledConatiner = styled(Container)`
  text-align: center;
  padding-top: 120px;
  padding-bottom: 120px;
`
export default NotFoundPage
